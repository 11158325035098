import React from "react";
import styles from "./Loading.module.scss";
import "animate.css";
function Loading() {
  return (
    <>
    {/* <div className={styles.loading}>
      <h1 className={styles.title}>Loading...</h1>
      <p className={`${styles.subtitle} ${styles["typing-animation"]}`}>
        Please wait
      </p>
    </div> */}
    <div className={styles.loading}>
  <h2 className="dot-1 animate__animated animate__hinge">😆😎🚀Welcome</h2>
  {/* <div class="dot-2 animate__animated animate__bounceIn"></div>
  <div class="dot-3 animate__animated animate__bounceIn"></div> */}
</div>
</>
  );
}

export default Loading;
