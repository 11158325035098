import React from 'react'
// import { Link } from 'react-router-dom'
import Button from "../../ui/Button"
import "./Home.scss"

const Icons = [
  {
    path: "https://www.leetcode.com/Princeton_",
    icon: "https://leetcode.com/static/images/LeetCode_logo.png",
  },
  {
    path: "https://www.leetcode.com/Princeton_",
    icon: "https://leetcode.com/static/images/LeetCode_logo.png",
  },
  {
    path: "https://www.leetcode.com/Princeton_",
    icon: "https://leetcode.com/static/images/LeetCode_logo.png",
  },
  {
    path: "https://www.leetcode.com/Princeton_",
    icon: "https://leetcode.com/static/images/LeetCode_logo.png",
  },

];

const Home = () => {
 
  return (
    <>
      <div className="home_container ">
        <div className="content">
          <h3>Hello I'm</h3>
          <h1>Princeton D'souza</h1>
          <p>
            I’m a web developer, open source enthusiast and a competitive coder.
          </p>
          <div className="iconlist">
            {Icons.map((item, index) => (
              <a href={item.path} keys={index} className="icon">
                <img src={item.icon} alt="leetcode" />
              </a >
            ))}
          </div>
          <Button>Resume</Button>
        </div>
        <div className="pic">
          <img
            src="https://i.pinimg.com/originals/e3/0e/36/e30e36d6b7a669b8dc5479424fe3138f.jpg"
            alt="pic"
          />
        </div>
      </div>
    </>
  );
}

export default Home