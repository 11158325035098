import {createRoot} from 'react-dom/client';
import App from "./App.jsx";
import "./index.scss";
import { BrowserRouter } from 'react-router-dom';
// switch to new react 18 syntax
createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
// old react 17 syntax
// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );
