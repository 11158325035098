import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";
const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <div className="navbar_logo">P</div>

        <div className="navbar_links">
          <ul>
            <li>
              <NavLink className="navlink" activeClassName="active" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="navlink" activeClassName="active" to="/">
                Skills
              </NavLink>
            </li>
            <li>
              <NavLink className="navlink" activeClassName="active" to="/">
                Experience
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
