import React from 'react'
import './Button.scss'
const Button = (props) => {
  return (
    <>
      <button className="btn">
        <span> {props.children}</span>
      </button>
    </>
  );
}

export default Button