import React from "react";
import "./NotFound.scss";
const NotFound = () => {
  return (
    <>
      <div className = "notbody">
        <div className="notfound">
                  This site is currently under development.👊✌️
        </div>
      </div>
    </>
  );
};

export default NotFound;
