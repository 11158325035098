
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import NotFound from './pages/404/NotFound';
import Navbar from './components/Navbar/Navbar';
import Loading from "./components/Loading/Loading"
import { useEffect, useState } from 'react';
function App() {


  const [loading, setLoading] = useState(true);

  let [devOne, setDevOne] = useState(0);
  let [devTwo, setDevTwo] = useState(0);
  let [devThree, setDevThree] = useState(0);
  let [devFour, setDevFour] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [flag, setFlag] = useState(false);

  const toggleOne = () => {
    setDevOne(devOne+=1);
  }
  const toggleTwo = () => {
    setDevTwo(devTwo+=1);
  }
  const toggleThree = () => {
    setDevThree(devThree+=1);
  }
  const toggleFour = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
    setDevFour(inputValue);
  }

  const toggleFlag = () => {
    
    if(devOne === 3 && devTwo === 4 && devThree === 7 && devFour === "porcupine") {
      setFlag(true);
    }else {
      setFlag(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    console.log(devOne, devTwo, devThree, devFour);
  })

  const func = () => {
    return (
      <>
      <button onClick={toggleOne}>{devOne}
        </button>
        <button onClick={toggleTwo}>{devTwo}</button>
        <button onClick={toggleThree}>{devThree}</button>
        <input type="text" value={inputValue} onChange={toggleFour} style={{width:15}} />
        <button onClick={toggleFlag}>🔥</button>
      </>
    )
  }
      
 const devMode = () => {
    return (
      <>
        <Navbar />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </>
    );
      }
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {func()}
          {flag ? (
            devMode()
          ) : (
            <Routes>
              <Route path="*" element={<NotFound />} />
              {/* <Route path="/" element={<Home />} />  */}
            </Routes>
          )}
        </>
      )}
    </>
  );
}

export default App;
